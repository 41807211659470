import { API_PAYMENTS, API_PAYMENTS_TRANSACTIONS } from "Constants/API";
import HttpHeadersAuthorization from "Helpers/common";
import { TPriceType } from "Types/cources";
import { ISuccessRes, IValidErrorRes } from "Types/responce";
import { ITransaction, TPaymentSystem } from "Types/transactions";
import { get, post } from "../axios";

export interface IInvoice {
  invoiceId: number;
  amount: number;
  currency: string;
  type: TPriceType;
  maxPeriod: number;
  paymentSystem: TPaymentSystem;
  paymentLink?: string;
}

export interface IPaymentData {
  priceId: number;
  paymentSystem: TPaymentSystem;
}

export const PaymentsServices = {
  async createPaymentInvoise(data: IPaymentData) {
    return post<ISuccessRes<IInvoice>>(API_PAYMENTS, data, {
      headers: HttpHeadersAuthorization(),
    })
      .then((res) => {
        return res.data.data;
      })
      .catch((e: IValidErrorRes) => {
        if (e.errors) {
          throw e.errors;
        }
        throw e;
      });
  },
  async getAllTransactions() {
    return get<ISuccessRes<ITransaction[]>>(API_PAYMENTS_TRANSACTIONS, {
      headers: HttpHeadersAuthorization(),
    }).then((res) => {
      return res.data.data;
    });
  },
};
