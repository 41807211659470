import { FC } from "react";

import { Icon, IconsName } from "Componens/common/Icon";
import { CourseContentType, ICourceContent } from "Types/cources";

import styles from "./index.module.css";

const iconsType: Record<CourseContentType, IconsName> = {
  announce: "streamAnnounce",
  file: "file",
  image: "image",
  link: "file",
  text: "text",
  video: "video",
  "video-kinescope": "video",
  "stream-kinescope": "stream",
  "stream-faceCast": "stream",
};

export const ContentItem: FC<ICourceContent> = ({ type, title }) => {
  return (
    <div className={styles["item__block"]}>
      <div className={styles["decor"]} />
      <div className={styles["icon_row"]}>
        <Icon icon={iconsType[type]} className={styles["icon"]} />
        <div>
          <div>{title}</div>
          <div className={styles["type"]}>{type.split("-")[0]}</div>
        </div>
      </div>
    </div>
  );
};
