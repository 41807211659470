import { usePayment } from "Hooks/api/usePayment";

import { FC } from "react";
import { useTranslation } from "react-i18next";

import { useSearchParams } from "react-router-dom";

import { TPaymentSystem } from "Types/transactions";
import { Loading } from "Componens/common";

import { ReactComponent as RusPaiments } from "./rus-payment.svg";
import { ReactComponent as OtherPaiments } from "./other-payment.svg";

import styles from "../index.module.css";
import modalStyles from "./styles.module.css";

export const ChoosePaymentMethod: FC = () => {
  const { t } = useTranslation();

  const { isLoading, mutate } = usePayment();

  const [search] = useSearchParams();

  const onClickPayCourse = (paymentSystem: TPaymentSystem) => {
    const priceId = search.get("priceId");

    if (priceId) mutate({ priceId: +priceId, paymentSystem });
  };

  return (
    <div className={styles["contentModal"]}>
      <div className={styles["title_modal"]}>
        <h3 className={modalStyles["title"]}>
          {t("modals.choosePaymentMethod.title")}
        </h3>
      </div>
      <div>
        <div className={modalStyles["text"]}>
          {t("modals.choosePaymentMethod.text")}
        </div>
        {isLoading ? (
          <div className={modalStyles["loading"]}>
            <Loading />
          </div>
        ) : (
          <div>
            <div
              onClick={() => onClickPayCourse("cloudPayments")}
              className={modalStyles["card"]}
            >
              <div className={modalStyles["card_text"]}>
                {t("modals.choosePaymentMethod.paymentMethod.0")}
              </div>
              <RusPaiments />
            </div>
            <div
              onClick={() => onClickPayCourse("stripe")}
              className={modalStyles["card"]}
            >
              <div className={modalStyles["card_text"]}>
                {t("modals.choosePaymentMethod.paymentMethod.1")}
              </div>
              <OtherPaiments />
            </div>
          </div>
        )}
      </div>
      <div className={modalStyles["description"]}>
        {t("modals.choosePaymentMethod.supportText")}
      </div>
    </div>
  );
};
