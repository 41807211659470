import { API_SUPPORT } from "Constants/API";
import HttpHeadersAuthorization from "Helpers/common";
import { IValidErrorRes } from "Types/responce";
import { post } from "../axios";

export const SupportServices = {
  async create(data: any) {
    return post(API_SUPPORT, data, {
      headers: HttpHeadersAuthorization(),
    }).catch((e: IValidErrorRes) => {
      if (e.errors) {
        throw e.errors;
      }
      throw e;
    });
  },
};
