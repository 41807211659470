import { FC } from "react";
import { AnnounceSpaceProps } from "./types";

import { Button } from "Componens/common/Button";
import { Link } from "react-router-dom";
import { URL_USER_COURSE } from "Constants/URL";

import styles from "./styles.module.css";
import { getUrlImg } from "Helpers/common";
import { useTranslation } from "react-i18next";

export const AnnounceSpace: FC<AnnounceSpaceProps> = ({
  title,
  description,
  img,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles["main"]}>
      <div className={styles["paid"]}>
        <div>Katia Txi</div>
      </div>
      <div className={styles["container"]}>
        <h3 className={styles["title"]}>{title}</h3>
        <div className={styles["row"]}>
          <p className={styles["description"]}>{description}</p>
          <Link to={URL_USER_COURSE} className={styles["btnBlock"]}>
            <Button className={styles["btn"]}>
              {t("cource.announce.toCourses")}
            </Button>
          </Link>
        </div>
        {img && (
          <div>
            <img src={getUrlImg(img)} alt="" className={styles["image"]} />
          </div>
        )}
      </div>
    </div>
  );
};
