import { ICourceUserContentAnnounce } from "Types/cources";
import { FC } from "react";

import styles from "./styles.module.css";

interface AnnounceContentProps {
  data: ICourceUserContentAnnounce["data"];
  title: string;
}

export const AnnounceContent: FC<AnnounceContentProps> = ({ data, title }) => {
  return (
    <div className={styles["block"]}>
      <div className={styles["titleData"]}>{data.dataTitle}</div>
      <div className={styles["contentText"]}>{data.description}</div>
    </div>
  );
};
