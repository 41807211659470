import { URL_USER_COURSE_ID } from "Constants/URL";
import { IInvoice, PaymentsServices } from "Helpers/api/Payments";
import { Pay } from "Helpers/common";
import { useAppDispatch, useAppSelector } from "Hooks/redux";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { ICourceItem } from "Types/cources";

export const usePayment = () => {
  const { setModalViewAction } = useAppDispatch();

  const { user } = useAppSelector((s) => s.App);

  const queryClient = useQueryClient();

  const location = useLocation();

  const courseIdRef = useRef<{ courseId: string | undefined }>({
    courseId: undefined,
  });

  useEffect(() => {
    if (location.pathname) {
      courseIdRef.current.courseId = location.pathname.split("/")[2];
    }
  }, [location]);

  const navigate = useNavigate();

  const [pay, setPay] = useState<IInvoice | undefined>();

  const { mutate, isLoading } = useMutation(
    PaymentsServices.createPaymentInvoise,
    {
      onSuccess: async (invoiceData) => {
        if (!invoiceData) return;

        setPay(invoiceData);
      },
      onError: (err) => {
        if (Array.isArray(err)) {
          setModalViewAction("errorCoursePaid");
        }
      },
    }
  );

  useEffect(() => {
    if (pay && user && courseIdRef.current.courseId) {
      setModalViewAction();
      // IF FREE
      if (+pay.amount === 0) {
        navigate(URL_USER_COURSE_ID(courseIdRef.current.courseId as string));
        return;
      }

      // IF STRIPE
      if (pay.paymentSystem === "stripe" && pay.paymentLink) {
        window.location.replace(pay.paymentLink);
        return;
      }

      // IF CloudPayments
      const courseTitle = queryClient.getQueryData<ICourceItem>([
        "cources",
        courseIdRef.current.courseId,
      ])?.courseMainInfo.title;

      const publcId = process.env.REACT_APP_PUBLIC_API_CLOUD_PAYMENTS;
      const currency = pay.currency;
      const invoiceId = pay.invoiceId;
      const email = user?.email || "";
      let amount = +pay.amount;
      if (pay.type === "recurrent" && pay.maxPeriod) {
        amount = amount / pay.maxPeriod;
      }
      amount = +amount.toFixed(0);
      const receipt = {
        Items: [
          {
            label: courseTitle, //наименование товара
            price: amount, //цена
            quantity: 1.0, //количество
            measurementUnit: "шт",
            amount: amount, //сумма
            vat: 0, //ставка НДС
            method: pay.type === "recurrent" ? 5 : 1, // тег-1214 признак способа расчета - признак способа расчета
            object: 4, // тег-1212 признак предмета расчета - признак предмета товара, работы, услуги, платежа, выплаты, иного предмета расчета
          },
        ],
        taxationSystem: 1, //система налогообложения; необязательный, если у вас одна система налогообложения
        email: email, //e-mail покупателя, если нужно отправить письмо с чеком
        isBso: false, //чек является бланком строгой отчетности
        amounts: {
          electronic: amount, // Сумма оплаты электронными деньгами
          advancePayment: 0.0, // Сумма из предоплаты (зачетом аванса) (2 знака после запятой)
          credit: 0.0, // Сумма постоплатой(в кредит) (2 знака после запятой)
          provision: 0.0, // Сумма оплаты встречным предоставлением (сертификаты, др. мат.ценности) (2 знака после запятой)
        },
      };
      let data: any = {
        CloudPayments: {
          CustomerReceipt: receipt,
        },
      };
      if (pay.type === "recurrent") {
        data.CloudPayments = {
          CustomerReceipt: receipt,
          recurrent: {
            interval: "Month",
            period: 1, // Сколько раз в interval
            maxPeriods: pay.maxPeriod - 1,
            customerReceipt: receipt,
          },
        };
      }
      Pay(
        {
          publicId: publcId,
          description: "",
          amount: amount,
          currency: currency,
          accountId: invoiceId,
          invoiceId: invoiceId,
          email: email,
          skin: "mini",
          data,
        },
        () => {
          const regex = /\/course\/(\d+)/;
          const match = window.location.pathname.match(regex);

          if (match) {
            const courseIdWindow = match[1];

            navigate(URL_USER_COURSE_ID(courseIdWindow));
          }
        }
      );
      setPay(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pay, user]);

  return { mutate, isLoading, setModalViewAction, pay, setPay };
};
