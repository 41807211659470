import { Button } from "Componens/common/Button";
// import { Icon } from "Componens/common/Icon";
import { WidthContext } from "Componens/main/widthWrapper";
import { t } from "i18next";
import { FC, useContext } from "react";
import { ICourceUserContent } from "Types/cources";

import { FileContent } from "./FileContent";
import { FinishContent } from "./FinishContent";
import { ImageContent } from "./ImageContent";
import { TextContent } from "./TextContent";
import { VideoContent } from "./VideoContent";
import { VideoKinescopeContent } from "./VideoKinescope";
import { AnnounceContent } from "./AnnounceContent";
import { StreamKinescopeContent } from "./StreamKinescope";
import { LinkContent } from "./LinkContent";
import { FaceCastContent } from "./FaceCastContent";

import styles from "./index.module.css";

interface WorkSpacePlayerProps {
  data: ICourceUserContent;
  onClickNextLesson: () => void;
  onCLickCansel: () => void;
  onClickStartCourseAgain: () => void;
  isFinish: boolean;
}

export const WorkSpacePlayer: FC<WorkSpacePlayerProps> = ({
  data,
  onClickNextLesson,
  onCLickCansel,
  onClickStartCourseAgain,
  isFinish,
}) => {
  const width = useContext(WidthContext);

  return (
    <div>
      <div className={styles["titleRow"]}>
        {width > 900 ? (
          <div className={styles["title"]}>{data.title}</div>
        ) : (
          <Button variant={"grey"} onClick={onCLickCansel}>
            {t("common.back")}
          </Button>
        )}
        {
          data.type !== "announce" && (
            // (width > 900 ? (
            <Button
              variant="white"
              onClick={isFinish ? onClickStartCourseAgain : onClickNextLesson}
            >
              {t("cource.nextLesson")}
            </Button>
          )
          // ) : (
          //   <Icon
          //     icon={"arrowLeftWhite"}
          //     style={{ transform: "rotate(180deg)" }}
          //     onClick={onClickNextLesson}
          //   />
          // ))
        }
      </div>
      <div className={styles["content"]}>
        {data.type === "text" && (
          <TextContent data={data.data} title={data.title} />
        )}
        {data.type === "video" && (
          <VideoContent data={data.data} title={data.title} />
        )}
        {data.type === "video-kinescope" && (
          <VideoKinescopeContent data={data.data} title={data.title} />
        )}
        {data.type === "image" && (
          <ImageContent data={data.data} title={data.title} />
        )}
        {data.type === "file" && (
          <FileContent data={data.data} title={data.title} />
        )}
        {data.type === "announce" && (
          <AnnounceContent data={data.data} title={data.title} />
        )}
        {data.type === "link" && (
          <LinkContent data={data.data} title={data.title} />
        )}
        {data.type === "stream-faceCast" && (
          <FaceCastContent data={data.data} title={data.title} />
        )}
        {data.type === "stream-kinescope" && (
          <StreamKinescopeContent data={data.data} title={data.title} />
        )}
        {data.type === "finish" && <FinishContent />}
      </div>
    </div>
  );
};
