import { FC } from "react";
import { Button } from "Componens/common/Button";

import { ICourceUserContentLink } from "Types/cources";

import styles from "./styles.module.css";

interface LinkContentProps {
  data: ICourceUserContentLink["data"];
  title: ICourceUserContentLink["title"];
}

export const LinkContent: FC<LinkContentProps> = ({ data, title }) => {
  return (
    <div className={styles["block"]}>
      <div className={styles["titleData"]}>{data.dataTitle}</div>
      <div className={styles["contentText"]}>{data.description}</div>

      <a href={data.url} target="_blank" rel="noreferrer">
        <Button>{data.label}</Button>
      </a>
    </div>
  );
};
