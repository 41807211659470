import { TOKEN } from "Constants/App";
import { URL_LOGIN } from "Constants/URL";
import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

const getToken = () => {
  const localStorageToken = localStorage.getItem(TOKEN);
  if (localStorageToken) {
    return localStorageToken;
  }
  const sessionStorageToken = sessionStorage.getItem(TOKEN);
  if (sessionStorageToken) {
    return sessionStorageToken;
  }

  return "";
};

export const apiClient = axios.create({
  baseURL,
});

apiClient.interceptors.request.use(
  (config) => {
    return {
      ...config,
      headers: {
        // Accept: "application/json",
        // "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    try {
      // const error = err as AxiosError;
      const response = error.response;
      if (response.status === 401 || response.status === 422) {
        // Do redirect here“https://example.com/login”;
        // store.auth = undefined;

        window.location.replace(URL_LOGIN);
        localStorage.removeItem(TOKEN);
        sessionStorage.removeItem(TOKEN);
        return Promise.reject(response.data);
      }

      if (response.status === 500) {
        // setToast({
        //   theme: "danger",
        //   text: "Something went wrong",
        //   id: generateRandomString(7),
        // });
        return Promise.reject(response.data);
      }

      if (response.status === 404) {
        // setToast({
        //   theme: "danger",
        //   text: "This page not found",
        //   id: generateRandomString(7),
        // });
        return Promise.reject(response.data);
      }

      if (response.status === 422) {
        return Promise.reject(response.data);
      }

      // if (error.response.data.message) {
      //   error.message = error.response.data.message // Message from backend
      // }

      // if(!axios.isAxiosError(error)){
      //   // do whatever you want with native error
      // }
      // // do what you want with your axios error

      //   setToast({
      //     theme: "danger",
      //     text: "Something went wrong",
      //     id: generateRandomString(7),
      //   });
      return Promise.reject(response.data);
    } catch (e) {
      //   setToast({
      //     theme: "danger",
      //     text: "Something went wrong",
      //     id: generateRandomString(7),
      //   });
    }
  }
);

const { get, post, put, delete: destroy } = apiClient;
export { get, post, put, destroy };
