import { FC, useMemo, useState } from "react";

import { ICourceUserContentStreamKinescope } from "Types/cources";

import styles from "./index.module.css";
import classNames from "classnames";

interface IVideoContnet {
  data: ICourceUserContentStreamKinescope["data"];
  title: string;
}

export const StreamKinescopeContent: FC<IVideoContnet> = ({ data, title }) => {
  const playId = useMemo(() => {
    if (!data) return "";

    const arrayLink = data.play_link.split("/");

    return arrayLink[arrayLink.length - 1];
  }, [data]);

  const [isShowChat, setIsShowChat] = useState(true);

  return (
    <div
      className={classNames(styles["videoContainer"], {
        [styles["gridFullSize"]]: !isShowChat,
      })}
    >
      <div
        className={classNames(styles["video"], {
          [styles["fullSize"]]: !isShowChat,
        })}
      >
        <div
          style={{ position: "relative", paddingTop: "56.25%", width: "100%" }}
        >
          <iframe
            title="stream"
            src={`https://kinescope.io/embed/${playId}`}
            allow="autoplay; fullscreen; picture-in-picture; encrypted-media;"
            allowFullScreen
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
            }}
          ></iframe>
        </div>
      </div>

      {isShowChat && (
        <div className={styles["chat"]}>
          <iframe
            title="chat"
            src={`https://kinescope.io/chat/${playId}?name=Alex`}
            allow="fullscreen"
            allowFullScreen
            width="100%"
            height="100%"
          ></iframe>
        </div>
      )}

      <div
        className={classNames(styles["optionsRow"], {
          [styles["optionsFullSize"]]: !isShowChat,
        })}
      >
        <div className={styles["title"]}>{title}</div>{" "}
        <button onClick={() => setIsShowChat((p) => !p)}>Screen</button>
      </div>
    </div>
  );
};
