import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ICourcePrice } from "Types/cources";
import { useAppDispatch, useAppSelector } from "Hooks/redux";
import { TransactionsCurrency } from "Componens/common/Currency";

import { useNavigate, useSearchParams } from "react-router-dom";
import { usePayment } from "Hooks/api/usePayment";
import { URL_USER_COURSE_ID } from "Constants/URL";

import styles from "./index.module.css";

interface CourcePricesProps {
  prices: ICourcePrice[];
  courseIsBought: boolean;
  courseId: string | number;
}

export const CourcePrices: FC<CourcePricesProps> = ({
  prices,
  courseIsBought,
  courseId,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles["prices"]}>
      <div className={styles["left_contentPrices"]}>
        <h3>{t("cource.price.PaymentOptions")}</h3>
      </div>

      <div className={styles["right_contentPrices"]}>
        {prices.map((price) => (
          <PriceItem
            {...price}
            key={"price" + price.id}
            courseIsBought={courseIsBought}
            courseId={courseId}
          />
        ))}
      </div>
    </div>
  );
};

interface PriceItemProps extends ICourcePrice {
  courseIsBought: boolean;
  courseId: string | number;
}

const PriceItem: FC<PriceItemProps> = ({
  sum,
  days,
  currency,
  id,
  type,
  maxPeriod,
  courseIsBought,
  courseId,
}) => {
  const { t } = useTranslation();

  const { isAuth } = useAppSelector((s) => s.App);

  const { setModalViewAction } = useAppDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = useSearchParams();

  const { mutate, isLoading } = usePayment();

  const navigate = useNavigate();

  const onClickPayCourse = () => {
    // IF NOT AUTH
    if (!isAuth) {
      return setModalViewAction("login");
    }

    // IF Course is paid
    if (courseIsBought) {
      return navigate(URL_USER_COURSE_ID(courseId));
    }

    // IF Free course
    if (+sum === 0) {
      return mutate({ priceId: id, paymentSystem: "free" });
    }

    setSearch(new URLSearchParams({ priceId: id.toString() }), {
      replace: true,
    });
    setModalViewAction("choosePaymentMethod");
  };

  return (
    <div className={styles["prices__item"]}>
      <div>
        <div className={styles["CurrencyPrice"]}>
          {+sum === 0 ? (
            <div className={styles["flex_currency"]}>
              {t("cource.price.priceItem.free")}
            </div>
          ) : (
            <div className={styles["flex_currency"]}>
              {type === "recurrent" && maxPeriod ? (
                <>
                  <TransactionsCurrency currency={currency} />
                  &nbsp;{(+sum / maxPeriod).toFixed(0)}
                  {" / "}
                  {t("cource.price.priceItem.period")}
                </>
              ) : (
                <>
                  <TransactionsCurrency currency={currency} />
                  &nbsp;{sum}
                </>
              )}
            </div>
          )}
        </div>
        <div className={styles["prices__item_descr"]}>
          {type === "recurrent" &&
            `${t("cource.price.priceItem.installmentPlan")} ${maxPeriod} ${t(
              "cource.price.priceItem.planPeriod"
            )} • `}

          {days === 36500
            ? `${t("cource.price.priceItem.permanentAccess")}`
            : `${days} ${t("cource.price.priceItem.daysAccess")}`}

          {/* {type === "recurrent" && ` • ${t("cource.price.priceItem.onlyRUS")}`} */}
        </div>
      </div>
      <button
        className={styles["prices__item_btn"]}
        onClick={onClickPayCourse}
        disabled={isLoading}
      >
        {t("cource.price.choose")}
      </button>
    </div>
  );
};
