import { FC } from "react";

import KinescopePlayer from "@kinescope/react-kinescope-player";

import styles from "./index.module.css";

interface IVideoContnet {
  data: string;
  title: string;
}

export const VideoKinescopeContent: FC<IVideoContnet> = ({ data, title }) => {
  return (
    <div className={styles["videoContainer"]}>
      <div className={styles["video"]}>
        <KinescopePlayer videoId={data} />
      </div>

      <div className={styles["title"]}>{title}</div>
    </div>
  );
};
