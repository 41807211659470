import { FC } from "react";

interface LinkContentProps {
  data: string;
  title: string;
}

export const FaceCastContent: FC<LinkContentProps> = ({ title, data }) => {
  return (
    <div style={{ height: "100%" }}>
      <iframe
        title="FaceCast"
        src={`https://facecast.net/w/${data}`}
        width="100%"
        height="100%"
        style={{ border: "none" }}
        allow="autoplay; fullscreen"
        allowFullScreen
      ></iframe>
    </div>
  );
};
